import { useState, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useAtom } from 'jotai';
import { masterDataAtom, coinsAtom, tapBotStateAtom, localClickerStateAtom } from '../../atoms';
import { useNavigate } from 'react-router-dom';
import { backButton } from '@telegram-apps/sdk-react';
import { useEffect } from 'react';


export const LeaguesRoute = () => {
    const navigate = useNavigate();
    const [masterData] = useAtom(masterDataAtom);
    const [coins] = useAtom(coinsAtom);
    const [localState] = useAtom(localClickerStateAtom);
    const [tapBotState] = useAtom(tapBotStateAtom);
    const [selectedLeagueIndex, setSelectedLeagueIndex] = useState(0);

    useEffect(() => {
        backButton.show();
        backButton.onClick(() => {
            navigate(-1);
            backButton.hide();
        });
        return () => {
            backButton.hide();
        };
    }, [navigate]);

    const getTotalEarnedCoins = useMemo(() => {
        return localState.localCoinCalculation.earnedCoins.clickEarned + tapBotState.botEarnedCoins;
    }, [localState, tapBotState]);

    const sortedLeagues = useMemo(() => {
        return [...masterData.leagues].sort(
            (a, b) => a.requiredClickEarnedCoins - b.requiredClickEarnedCoins
        );
    }, [masterData.leagues]);

    const nextUnachievedLeagueIndex = useMemo(() => {
        const totalCoins = coins.clickEarnedCoins + tapBotState.botEarnedCoins;
        return sortedLeagues.findIndex(
            league => league.requiredClickEarnedCoins > totalCoins
        );
    }, [sortedLeagues, coins.clickEarnedCoins, tapBotState.botEarnedCoins]);

    useEffect(() => {
        if (nextUnachievedLeagueIndex !== -1) {
            setSelectedLeagueIndex(nextUnachievedLeagueIndex);
        }
    }, [nextUnachievedLeagueIndex]);

    const selectedLeague = sortedLeagues[selectedLeagueIndex];
    const canNavigatePrev = selectedLeagueIndex > 0;
    const canNavigateNext = selectedLeagueIndex < sortedLeagues.length - 1;

    const handlePrevLeague = () => {
        if (canNavigatePrev) {
            setSelectedLeagueIndex(prev => prev - 1);
        }
    };

    const handleNextLeague = () => {
        if (canNavigateNext) {
            setSelectedLeagueIndex(prev => prev + 1);
        }
    };

    const getCoinsDisplay = () => {
        const required = selectedLeague.requiredClickEarnedCoins;
        if (getTotalEarnedCoins >= required) {
            return `From ${required.toLocaleString()}`;
        }
        return `${getTotalEarnedCoins.toLocaleString()} / ${required.toLocaleString()}`;
    };

    return (
        <div className="min-h-screen bg-[#F7F8F8] p-4">
            <div className='mb-8'>
                <div className="flex items-center justify-center mb-2">
                    <h1 className="text-2xl font-inter font-light text-[#1A1A1A]">{selectedLeague.name}</h1>
                </div>
                <div className="flex items-center justify-center mb-2">
                    <p className="text-sm font-inter font-light text-[#666666]">Stage coins = earned by click + tapbot</p>
                </div>
                <div className="flex items-center justify-center mb-2">
                    <p className="text-sm font-inter font-light text-[#666666]">{selectedLeague.description}</p>
                </div>
            </div>
            <div className="flex items-center justify-between mb-8">
                <button
                    onClick={handlePrevLeague}
                    disabled={!canNavigatePrev}
                    className={`p-2 rounded-full ${canNavigatePrev
                        ? 'text-[#1A1A1A]'
                        : 'text-gray-300'
                        }`}
                >
                    <ChevronLeft size={36} />
                </button>

                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={selectedLeague.badgeSmallUrl}
                        alt={selectedLeague.name}
                        className="w-64 h-64 object-contain"
                    />
                </div>

                <button
                    onClick={handleNextLeague}
                    disabled={!canNavigateNext}
                    className={`p-2 rounded-full ${canNavigateNext
                        ? 'text-[#1A1A1A]'
                        : 'text-gray-300'
                        }`}
                >
                    <ChevronRight size={36} />
                </button>
            </div>

            <div className="p-4">
                <div className="flex flex-col items-center mb-4">
                    <div className="font-light text-[#666666] text-xl">
                        {getCoinsDisplay()}
                    </div>
                </div>

                <div className="h-6 bg-[#E6E6E6] rounded-full overflow-hidden p-1">
                    <div
                        className="h-full bg-[#F15A24] rounded-full transition-all duration-300"
                        style={{
                            width: `${Math.min(100, (getTotalEarnedCoins / selectedLeague.requiredClickEarnedCoins) * 100)}%`
                        }}
                    />
                </div>
            </div>
        </div >
    );
};

export default LeaguesRoute;