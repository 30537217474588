import { useEffect, useState } from 'react';
import fujiyamaImage from '../../../assets/fujiyamatap-icons/stage/fujiyama.webp'

export interface CoinPopupProps {
    id: number;
    tapbotLevel: number;
    amount: number;
    x: number;
    y: number;
    onComplete: () => void;
}

export const CoinPopup = ({ amount, x, y, onComplete, tapbotLevel }: CoinPopupProps) => {
    const [style, setStyle] = useState({
        transform: 'translate(-50%, -50%)',
        opacity: 1,
        top: y,
        left: x,
        transition: 'none'
    });

    useEffect(() => {
        requestAnimationFrame(() => {
            setStyle({
                transform: 'translate(-50%, -100%)',
                opacity: 0,
                top: y - 80,
                left: x,
                transition: 'all 1s ease-out'
            });
        });

        const timer = setTimeout(onComplete, 1000);
        return () => clearTimeout(timer);
    }, [x, y, onComplete]);

    return (
        <div>
            {tapbotLevel !== 3 ? (
                <div
                    className="fixed pointer-events-none text-white font-bold text-lg"
                    style={{
                        ...style,
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
                    }}
                >
                    +{amount.toLocaleString()}
                </div>
            ) : (
                <div
                    className="fixed pointer-events-none text-white font-bold text-lg flex items-center gap-1"
                    style={{
                        ...style,
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
                    }}
                >
                    <span>+{amount.toLocaleString()}</span>
                    <img
                        src={fujiyamaImage}
                        alt="fujiyama"
                        className="w-4 h-4 object-contain"
                    />
                </div>
            )}
        </div>
    );
};

export default CoinPopup;