import { Check } from 'lucide-react';
import coinIcon from '../../assets/fujiyamatap-icons/ui/coin.webp';
import { TaskReward } from '../../../functions/api/v1/master/type';

interface SingleTaskCardProps {
    task: {
        taskId: string;
        title: string;
        reward: TaskReward[];
        requiredCoins: number;
    };
    currentCoins: number;
    status: string;
    canClaim: boolean;
    completedAt?: number;
    rewardClaimed: boolean;
    taskIconUrl?: string;
    currentTaskLevel: number;
    onClaim: (taskId: string) => void;
}

const SingleTaskCard = ({
    task,
    currentCoins,
    canClaim,
    rewardClaimed,
    taskIconUrl,
    currentTaskLevel,
    onClaim
}: SingleTaskCardProps) => {
    // rewardClaimedの場合、reward配列の最初の要素が受領済みの報酬
    const displayReward = rewardClaimed
        ? task.reward[0].reward
        : task.reward.find(r => r.level === currentTaskLevel)?.reward || 0;

    const progressPercentage = Math.min(100, (currentCoins / task.requiredCoins) * 100);

    return (
        <div className="w-full border border-[#B3B3B3] rounded-lg p-4">
            <div className="flex items-center mb-4">
                <div className="w-12 h-12 shrink-0 mr-4">
                    <img
                        src={taskIconUrl}
                        alt={task.title}
                        className="w-full h-full object-contain"
                    />
                </div>
                <div className="min-w-0 flex-1">
                    <h3 className="text-base font-light text-gray-900 break-words pr-2">
                        {task.title}
                    </h3>
                    <div className="flex items-center mt-1">
                        <img src={coinIcon} alt="Coin" className="w-4 h-4 mr-1" />
                        <span className="text-sm font-light text-gray-600">
                            {displayReward.toLocaleString()} coins {rewardClaimed && <span className="text-green-600 ml-1">Claimed</span>}
                        </span>
                        {!rewardClaimed && (
                            <span className="text-sm font-light ml-2 text-gray-600">
                                Level {currentTaskLevel}
                            </span>
                        )}
                    </div>
                </div>
                {rewardClaimed ? (
                    <Check className="w-10 h-10 text-[#DE6437] ml-4" />
                ) : canClaim ? (
                    <button
                        onClick={() => onClaim(task.taskId)}
                        className="px-4 py-2 bg-[#DE6437] text-white rounded-lg ml-4"
                    >
                        Claim
                    </button>
                ) : null}
            </div>
            <div className="mt-4">
                <div className="h-6 bg-[#E6E6E6] rounded-full overflow-hidden p-1">
                    <div
                        className="h-full bg-[#F15A24] rounded-full transition-all duration-300"
                        style={{
                            width: `${progressPercentage}%`
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SingleTaskCard;