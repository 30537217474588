import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';
import coinIcon from '../../assets/fujiyamatap-icons/ui/coin.webp'
import tonIcon from '../../assets/fujiyamatap-icons/ui/ton_symbol.svg';

// 通貨アイコンのマッピング
const CURRENCY_ICONS: Record<string, string> = {
    'coin': coinIcon,
    'ton': tonIcon
};

type ConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    icon?: string;
    title: string;
    description: string;
    confirmText?: string;
    isLoading?: boolean;
    inventory?: {
        remains: number;
        total?: number;
    };
    price?: {
        amount: number;
        currency: string;
    };
};

const ConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    icon,
    title,
    description,
    confirmText = 'Confirm',
    isLoading = false,
    inventory,
    price,
}: ConfirmationModalProps) => {
    const [isClosing, setIsClosing] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsMounted(true);
        }
        return () => {
        };
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
            setIsMounted(false);
        }, 300);
    };

    if (!isMounted) return null;

    const renderInventory = () => {
        if (!inventory) return null;
        const { remains, total } = inventory;
        return (
            <p className="text-sm text-center text-gray-600">
                Remaining: {total ? `${remains}/${total}` : remains}
            </p>
        );
    };

    const renderPrice = () => {
        const coinIcon = CURRENCY_ICONS['coin'];

        if (!price) {
            return (
                <div className="flex items-center justify-center gap-2">
                    {coinIcon && (
                        <img
                            src={coinIcon}
                            alt="coin"
                            className="w-5 h-5 object-contain"
                        />
                    )}
                    <span className="text-lg font-medium font-mplus1p">Free</span>
                </div>
            );
        }

        const { amount, currency } = price;
        const currencyIcon = CURRENCY_ICONS[currency] || coinIcon;

        return (
            <div className="flex items-center justify-center gap-2">
                {currencyIcon && (
                    <img
                        src={currencyIcon}
                        alt={currency}
                        className="w-5 h-5 object-contain"
                    />
                )}
                <span className="text-lg font-medium font-mplus1p">{amount.toLocaleString()}</span>
            </div>
        );
    };

    return createPortal(
        <div
            className={`fixed inset-0 z-50 flex items-end justify-center sm:items-center
        ${isOpen ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        >
            {/* Backdrop */}
            <div
                className={`absolute inset-0 bg-black ${isOpen && !isClosing ? 'opacity-50' : 'opacity-0'} 
          transition-opacity duration-300`}
                onClick={handleClose}
            />

            {/* Modal */}
            <div
                className={`relative w-full sm:max-w-lg bg-white rounded-t-xl sm:rounded-xl 
          transform transition-transform duration-300 ease-out
          ${isOpen && !isClosing ? 'translate-y-0' : 'translate-y-full'}`}
            >
                {/* Close button */}
                <button
                    onClick={handleClose}
                    className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 
            transition-colors duration-200"
                >
                    <X className="w-5 h-5 text-gray-500" />
                </button>

                {/* Content */}
                <div className="p-6 space-y-4">
                    {/* Icon */}
                    {icon && (
                        <div className="flex justify-center">
                            <div className="w-16 h-16 flex-shrink-0">
                                <img
                                    src={icon}
                                    alt={title}
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        </div>
                    )}

                    {/* Title */}
                    <h3 className="text-lg font-mplus1p font-semibold text-center text-gray-900">
                        {title}
                    </h3>

                    {/* Description */}
                    <p className="text-sm font-mplus1p text-center text-gray-600">
                        {description}
                    </p>
                    {!price && (
                        <p className="text-sm font-mplus1p text-center text-gray-600">
                            Due to huge access is coming, wait around 20 seconds after purchasing.
                        </p>
                    )}

                    {/* Inventory Info */}
                    {renderInventory()}

                    {/* Price Info */}
                    {renderPrice()}

                    {/* Confirm Button */}
                    <button
                        onClick={onConfirm}
                        disabled={isLoading}
                        className="w-full py-3 px-4 text-white rounded-lg
              font-medium transition-colors duration-200
              bg-[#DE6437] hover:bg-[#C55832] focus:outline-none focus:ring-2 
              focus:ring-[#DE6437] focus:ring-offset-2 font-mplus1p
              disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center space-x-2">
                                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                <span>Processing...</span>
                            </div>
                        ) : (
                            confirmText
                        )}
                    </button>
                </div>

                {/* Bottom safe area spacing for mobile */}
                <div className="h-[env(safe-area-inset-bottom)]" />
            </div>
        </div>,
        document.body
    );
};

export default ConfirmationModal;