import { Link } from 'react-router-dom';
import { CinemaTask, SpecialTask } from '../../../functions/api/v1/master/type';
import { ChevronRight, Check } from 'lucide-react';
import coinIcon from '../../assets/fujiyamatap-icons/ui/coin.webp'

interface MultiTaskCardProps {
    task: CinemaTask | SpecialTask;
    status: string;
    style: string;
    linkPath: string;
    currentTaskLevel: string;
    reward: number;
    isRewardClaimed: boolean;
    taskIconUrl?: string;
}

const MultiTaskCard = ({
    task,
    linkPath,
    currentTaskLevel,
    reward,
    isRewardClaimed,
    taskIconUrl
}: MultiTaskCardProps) => {
    return (
        <Link
            to={linkPath}
            className="w-full flex border border-[#B3B3B3] rounded-lg hover:bg-gray-50 transition-colors duration-200"
        >
            <div className="flex-1 flex items-center p-4">
                {taskIconUrl && (
                    <img
                        src={taskIconUrl}
                        alt={task.title}
                        className="w-12 h-12 object-cover rounded shrink-0 mr-4"
                    />
                )}
                <div className="min-w-0 flex-1">
                    <h3 className="text-base font-light text-gray-900 break-words pr-2">
                        {task.title}
                    </h3>
                    <div className="flex items-center mt-1">
                        <img src={coinIcon} className="w-4 h-4 mr-1" />
                        <span className="text-sm font-light text-gray-600">
                            {reward.toLocaleString()} coins
                            {isRewardClaimed && <span className="text-green-600 ml-1">Claimed</span>}
                        </span>
                        {currentTaskLevel && (
                            <span className="text-sm font-light text-gray-400 ml-2">
                                {currentTaskLevel}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex items-center px-4 shrink-0">
                {isRewardClaimed ? (
                    <Check className="w-10 h-10 text-[#DE6437]" />
                ) : (
                    <ChevronRight className="w-10 h-10 text-[#333333]" />
                )}
            </div>
        </Link>
    );
};

export default MultiTaskCard;