
import gameIcon from '../../assets/fujiyamatap-icons/bottom-menu-buttons/game.webp';

export const LoadingScreen = () => {
    return (
        <div className="h-screen flex items-center justify-center"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <div className="flex flex-col items-center gap-8">
                {/* Rotating game icon */}
                <div className="relative w-32 h-32 animate-spin-slow">
                    <img
                        src={gameIcon}
                        alt="Loading"
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>
            <div className="text-center text-gray-500 text-sm">
                <p>So many access is coming...</p>
                <p>It may take 10 seconds...</p>
                <p>To send your clicks, wait 1 sec before closing...</p>
            </div>
        </div>
    );
};

export default LoadingScreen;