import { useAtom } from 'jotai';
import { masterDataAtom, taskProgressAtom, levelsAtom } from '../../../atoms';
import { paths } from '../../../../config/paths';
import { TaskTabs } from '../../../../components/tasks/tasktabs';
import { SpecialTask } from '../../../../../functions/api/v1/master/type';
import MultiTaskCard from '../../../../components/tasks/MultiTaskCard';
import specialTaskIcon from '../../../../assets/fujiyamatap-icons/task-items/special-task.webp';

type TaskStatus = 'inprogress' | 'notstarted' | 'completed';
type TaskGroup = Record<TaskStatus, Record<'sponsored' | 'regular', SpecialTask[]>>;

const TASK_STATUS_CONFIG = {
    inprogress: { title: 'In Progress', order: 1 },
    notstarted: { title: 'Not Started', order: 2 },
    completed: { title: 'Completed', order: 3 },
} as const;

export const SpecialRoute = () => {
    const [masterData] = useAtom(masterDataAtom);
    const [taskProgress] = useAtom(taskProgressAtom);
    const [levels] = useAtom(levelsAtom);

    // 現在のレベルに応じた報酬額を計算
    const getCurrentLevelReward = (task: SpecialTask) => {
        const currentLevel = levels.specialTaskLevel;
        console.log(task)
        console.log(currentLevel)
        const reward = task.rewards.find(r => r.level == currentLevel);
        console.log(reward);
        return reward ? reward.reward : task.rewards[0].reward;
    };

    const getTaskStatus = (taskId: string): { status: TaskStatus; style: string } => {
        const progress = taskProgress.specialTasks.find(p => p.taskId === taskId);
        if (!progress) return { status: 'notstarted', style: 'bg-gray-100 text-gray-800' };
        if (progress.completedAt) return { status: 'completed', style: 'bg-green-100 text-green-800' };
        return { status: 'inprogress', style: 'bg-blue-100 text-blue-800' };
    };

    const groupedTasks = masterData.specialTasks.reduce<TaskGroup>((acc, task) => {
        const { status } = getTaskStatus(task.taskId);
        const group = task.isSponsored ? 'sponsored' : 'regular';

        if (!acc[status]) {
            acc[status] = { sponsored: [], regular: [] };
        }

        acc[status][group].push(task);
        return acc;
    }, {
        inprogress: { sponsored: [], regular: [] },
        notstarted: { sponsored: [], regular: [] },
        completed: { sponsored: [], regular: [] },
    });

    // Sort each subgroup by taskOrder
    Object.values(groupedTasks).forEach(group => {
        group.sponsored.sort((a, b) => a.taskOrder - b.taskOrder);
        group.regular.sort((a, b) => a.taskOrder - b.taskOrder);
    });

    const renderTaskGroup = (statusKey: TaskStatus) => {
        const group = groupedTasks[statusKey];
        const { sponsored, regular } = group;

        if (!sponsored.length && !regular.length) return null;

        return (
            <div className="mb-8 mx-4" key={statusKey}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {sponsored.concat(regular).map((task) => {
                        const { status, style } = getTaskStatus(task.taskId);
                        const progress = taskProgress.specialTasks.find(p => p.taskId === task.taskId);

                        // 表示するレベルと報酬額を決定
                        let displayLevel = '';
                        let displayReward = 0;

                        if (progress?.rewardClaimed) {
                            // 報酬受け取り済みの場合は受け取った報酬額のみを表示
                            displayReward = progress.rewardCoins;
                        } else {
                            // 未受け取りの場合は現在のレベルと対応する報酬額を表示
                            displayLevel = `Lv ${levels.specialTaskLevel}`;
                            displayReward = getCurrentLevelReward(task);
                        }

                        return (
                            <MultiTaskCard
                                key={task.taskId}
                                task={task}
                                status={status}
                                style={style}
                                linkPath={paths.tasks.special.task.path.replace(':specialTaskId', task.taskId)}
                                currentTaskLevel={displayLevel}
                                reward={displayReward}
                                isRewardClaimed={progress?.rewardClaimed || false}
                                taskIconUrl={specialTaskIcon}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-7xl mx-auto overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <TaskTabs />
            {(Object.keys(TASK_STATUS_CONFIG) as TaskStatus[])
                .sort((a, b) => TASK_STATUS_CONFIG[a].order - TASK_STATUS_CONFIG[b].order)
                .map(renderTaskGroup)}
        </div>
    );
};

export default SpecialRoute;