import React, { useState } from 'react';
import { ChevronRight, Sparkles } from 'lucide-react';
import ConfirmationModal from '../../../ConfirmationModal/ConfirmationModal';
import type {
    TapLevelPrice,
    EnergyLimitLevelPrice,
    RechargeSpeedLevelPrice,
    TapBotLevelPrice
} from '../../../../../functions/api/v1/master/type';
import { useToast } from '../../../toast/useToast';

import coinIcon from '../../../../assets/fujiyamatap-icons/ui/coin.webp';
import tonIcon from '../../../../assets/fujiyamatap-icons/ui/ton_symbol.svg';

const CURRENCY_ICONS: Record<string, string> = {
    'coin': coinIcon,
    'ton': tonIcon
};

type BaseLevelPrice = TapLevelPrice | EnergyLimitLevelPrice | RechargeSpeedLevelPrice;
type AnyLevelPrice = BaseLevelPrice | TapBotLevelPrice;

interface BaseItemOutlineButtonProps {
    title: string;
    iconUrl: string;
    description: string;
    currentLevel: number;
    nextLevel?: number;
    hasPremiumTapBot: boolean;
    canPurchase: boolean;
    purchasing: boolean;
    isMaxLevel: boolean;
    isDisabled?: boolean;
    onPurchase: (priceId: string, price: number, currencyId: string) => Promise<void>;
}

interface RegularItemProps extends BaseItemOutlineButtonProps {
    isTapBot?: false;
    prices: BaseLevelPrice[];
}

interface TapBotItemProps extends BaseItemOutlineButtonProps {
    isTapBot: true;
    isHighestTier: boolean;
    duration: number;
    stock?: number;
    remains?: number;
    specialFeatures?: React.ReactNode[];
    prices: TapBotLevelPrice[];
}

export type ItemOutlineButtonProps = RegularItemProps | TapBotItemProps;

const ItemOutlineButton: React.FC<ItemOutlineButtonProps> = ({
    title,
    iconUrl,
    description,
    currentLevel,
    nextLevel,
    hasPremiumTapBot,
    canPurchase,
    purchasing,
    isMaxLevel,
    isDisabled,
    onPurchase,
    prices,
    ...props
}) => {
    const { showToast } = useToast();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState<AnyLevelPrice | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const isTapBot = 'isTapBot' in props && props.isTapBot;

    const getButtonText = () => {
        if (isTapBot) {
            if (!nextLevel) return 'Max Level';
            if (props.remains === 0) return 'Out of Stock';
            return currentLevel ? 'Level Up' : 'Activate';
        }

        if (hasPremiumTapBot) {
            return `✨ ${title} Maximized by Premium TapBot`;
        }
        return isMaxLevel ? 'Max Level' : 'Level Up';
    };

    const getDisabledState = () => {
        if (isDisabled) return true;
        if (!canPurchase) return true;
        if (purchasing) return true;
        if (isTapBot && props.remains === 0) return true;
        return false;
    };

    const getButtonStyle = () => {
        const baseStyle = "w-full border border-[#B3B3B3] rounded-lg p-4";
        const isButtonDisabled = getDisabledState();

        if (isButtonDisabled) {
            return `${baseStyle} opacity-50 cursor-not-allowed`;
        }
        return `${baseStyle} hover:bg-gray-50`;
    };

    const getSuccessMessage = () => {
        if (isTapBot) {
            return `TapBot Level ${nextLevel} activated! Your bot will now click automatically.`;
        }
        return `${title} upgraded to Level ${nextLevel}!`;
    };

    const handleClick = (price: AnyLevelPrice) => {
        if (!getDisabledState()) {
            setSelectedPrice(price);
            setIsModalOpen(true);
        }
    };

    const handleConfirm = async () => {
        if (!selectedPrice) return;

        try {
            setIsLoading(true);
            // onPurchaseの結果を待つ
            await onPurchase(
                selectedPrice.priceId,
                selectedPrice.price,
                selectedPrice.currencyId
            );

            // TON支払いの場合は、成功のトーストは表示しない
            // （成功のトーストは支払い完了後に表示される）
            if (selectedPrice.currencyId !== 'ton') {
                showToast("Success", "success", getSuccessMessage());
            }
        } catch (error) {
            console.error('Purchase failed:', error);
            showToast(
                "Purchase Failed",
                "error",
                'The purchase could not be completed. Please try again.'
            );
        } finally {
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedPrice(null);
    };

    return (
        <>
            {prices.map((price) => (
                <button
                    key={price.priceId}
                    onClick={() => handleClick(price)}
                    disabled={getDisabledState()}
                    className={getButtonStyle()}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                            <img src={iconUrl} alt={title} className="w-12 h-12" />

                            <div>
                                <div className="flex items-center gap-2">
                                    <span className="text-base font-light font-mplus1p text-[#1A1A1A]">
                                        {title}
                                    </span>
                                    {isTapBot && props.isHighestTier && (
                                        <span className="text-yellow-500 flex items-center gap-1 font-mplus1p">
                                            <Sparkles className="w-4 h-4" />
                                            Premium
                                        </span>
                                    )}
                                </div>

                                <div className="flex items-center gap-2 mt-1">
                                    {price.price > 0 && (
                                        <div className="flex items-center gap-1">
                                            <img
                                                src={CURRENCY_ICONS[price.currencyId]}
                                                alt=""
                                                className="w-4 h-4"
                                            />
                                            <span className="text-sm text-gray-600 font-mplus1p">
                                                {price.price.toLocaleString()} {price.currencyId === 'ton' ? 'TON' : 'coins'}
                                            </span>
                                        </div>
                                    )}
                                    {isTapBot && props.stock !== undefined && props.stock !== null && props.remains !== undefined && props.remains !== null && (
                                        <span className="text-sm text-gray-600 font-mplus1p">
                                            ({props.remains}/{props.stock})
                                        </span>
                                    )}
                                </div>

                                {hasPremiumTapBot && !isTapBot && (
                                    <div className="text-sm text-yellow-600 flex items-center font-mplus1p gap-1 mt-1">
                                        <Sparkles className="w-4 h-4" />
                                        Maximized by Premium TapBot
                                    </div>
                                )}

                                {isMaxLevel && !hasPremiumTapBot && !isTapBot && (
                                    <div className="text-sm text-gray-600 mt-1 font-mplus1p">
                                        Max Level Reached
                                    </div>
                                )}
                            </div>
                        </div>

                        <ChevronRight className="w-10 h-10 text-[#333333]" />
                    </div>
                </button>
            ))}

            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                icon={iconUrl}
                title={title}
                description={description}
                confirmText={getButtonText()}
                isLoading={isLoading}
                price={selectedPrice ? {
                    amount: selectedPrice.price,
                    currency: selectedPrice.currencyId
                } : undefined}
            />
        </>
    );
};

export default ItemOutlineButton;